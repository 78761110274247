import React from "react";

import Box from "../Box";
import { cover, gradientBg } from "../../../utils/styles";

export default function GradientBorderCard({ sx, ...otherProps }) {
  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: "card",
        background: "white",
        p: 4,
        backgroundClip: "padding-box",
        border: "solid 4px transparent",
        "&:before": {
          content: "''",
          ...cover,
          zIndex: -1,
          margin: "-4px",
          borderRadius: "inherit",
          ...gradientBg,
        },
        ...sx,
      }}
      {...otherProps}
    />
  );
}
