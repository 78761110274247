import React from "react";

import GridRow from "../../commons/GridRow";
import Heading from "../../commons/Heading";
import Text from "../../commons/Text";
import RichTextRenderer from "../../commons/RichTextRenderer";
import GradientBorderCard from "../../commons/GradientBorderCard";
import useGlobalState from "../../../utils/useGlobalState";

export default function OfficesBlock({ _rawTitle, cards, ...otherProps }) {
  const [globalState] = useGlobalState();
  const { allSanityOffice } = globalState;
  const offices = allSanityOffice.nodes;
  return (
    <GridRow
      as="section"
      sx={{
        backgroundColor: "background",
        py: [5, "gridGap"],
        textAlign: ["center", "start"],
      }}
    >
      <GridRow.Col gridColumn={["start / end", "start / span 5"]}>
        <RichTextRenderer blocks={_rawTitle} />
      </GridRow.Col>
      <GridRow.Col gridColumn={["start / end", "7 / end"]} sx={{ mt: [4, 0] }}>
        <GridRow
          subGrid
          variant="half"
          gap={4}
          sx={{
            position: "relative",
            zIndex: 0,
            gridAutoRows: ["auto", "minmax(280px, auto)"],
          }}
        >
          {offices.map((office) => (
            <GradientBorderCard
              key={office.title}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Heading as="h3">{office.title}</Heading>
              <Text
                as="p"
                sx={{
                  mt: 3,
                }}
              >
                {office.address.street}
              </Text>
              <Text>{office.address.city}</Text>
              <Text>{office.address.postcode}</Text>
              <Text as="p" sx={{ mt: [4, "auto"] }}>
                Email:{" "}
                <a href={`mailto:${office.address.email}`}>
                  {office.address.email}
                </a>
              </Text>
            </GradientBorderCard>
          ))}
        </GridRow>
      </GridRow.Col>
    </GridRow>
  );
}
